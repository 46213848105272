.list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actions {
  display: flex;
  flex-direction: row;
}
