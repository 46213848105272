.login-screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-background-color);
}

.app-logo {
  margin-top: -15rem;
  margin-bottom: 5rem;
}

#login-button {
  color: white;
  background-color: var(--primary-background-color);
}

.error-message {
  color: red;
  font-weight: bold;
  position: absolute;
}
