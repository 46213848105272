.users {
  flex: 1;
  padding: 5vh 0;
  background-color: var(--secondary-background-color);
}

.content {
  margin: 0 3vw;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  min-height: 20%;
}

.search {
  margin-bottom: 2vh;
}
