.logo {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.header .anticon {
  color: var(--secondary-background-color);
  font-size: 18px;
}

.anticon:hover {
  color: var(--hover-color);
}

.screen {
  display: flex;
  flex: 1;
}

.default-screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-background-color);
  padding: 10px;
}

.default-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--tertiary-background-color);
  margin-bottom: 2vh;
  width: 90%;
}

.default-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.default-list-item {
  padding: 5px;
  background-color: rgb(200, 200, 200, 0.15);
}
