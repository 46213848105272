.folders {
  flex: 1;
  background-color: var(--secondary-background-color);
  padding: 10px;
}

.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
