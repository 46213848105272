#root {
  display: flex;
  flex: 1;
  min-height: 100%;
}

:root {
  --primary-background-color: #001529;
  --secondary-background-color: rgba(211, 239, 248, 0.74);
  --tertiary-background-color: #fafafa;
  --primary-border-color: #d9d9d9;
  --hover-color: #1890ff;
}

.ant-btn {
  margin-left: 0.5vw;
}

/*
  May not be needed. Added due to some white space at the end of the page
*/
html {
  line-height: 1.5;
}
