.user-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 3vh;
  justify-content: space-between;
  border-bottom: solid 1px var(--primary-border-color);
}

.user-container {
  display: flex;
  flex-direction: column;
}

.ant-btn {
  margin-left: 1vw;
}

.line {
  width: 112px;
  height: 47px;
  border-bottom: 10px solid black;
  position: absolute;
}

.pdf-controls {
  display: flex;
  margin-top: 1vh;
  margin-bottom: 1vh;
  align-items: center;
  justify-content: center;
}

.data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
