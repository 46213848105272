.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 1000;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

td {
  min-width: 100px;
}
